import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42')
];

export const server_loads = [3,5];

export const dictionary = {
		"/(site)": [~11,[3]],
		"/(site)/about": [~12,[3]],
		"/(site)/admin": [~13,[3,4]],
		"/(site)/admin/cache": [~14,[3,4]],
		"/(site)/admin/shows": [~15,[3,4]],
		"/(site)/admin/shows/[show_number]": [~16,[3,4]],
		"/(site)/admin/transcripts": [~17,[3,4]],
		"/(site)/admin/videos": [~18,[3,4]],
		"/(site)/admin/videos/import": [~20,[3,4]],
		"/(site)/admin/videos/[playlist_id]": [~19,[3,4]],
		"/(blank)/embed/[show_number]": [~8,[2]],
		"/(site)/guests": [~22,[3]],
		"/(site)/guest/[name_slug]": [~21,[3]],
		"/(blank)/links": [9,[2]],
		"/(site)/login": [23,[3]],
		"/(blank)/og/[show_number_or_title]": [~10,[2]],
		"/(site)/pages/[page]": [~24,[3]],
		"/(site)/potluck": [~25,[3]],
		"/(site)/search": [26,[3]],
		"/(site)/shows": [~30,[3]],
		"/(site)/show/[show_number]/[slug]": [~28,[3,5]],
		"/(site)/show/[show_number]/[slug]/transcript": [~29,[3,5]],
		"/(site)/show/[...all]": [~27,[3]],
		"/(site)/sickpicks": [~31,[3]],
		"/(site)/snackpack": [~32,[3]],
		"/(site)/snackpack/[issue_id]": [~33,[3]],
		"/(site)/suggest": [34,[3]],
		"/(site)/system/colors": [35,[3,6]],
		"/(site)/system/components": [36,[3,6]],
		"/(site)/system/layout": [37,[3,6]],
		"/(site)/system/theme": [~38,[3,6]],
		"/(site)/system/typography": [39,[3,6]],
		"/(site)/videos": [~40,[3,7]],
		"/(site)/videos/[p_slug]": [~41,[3,7]],
		"/(site)/videos/[p_slug]/[v_slug]": [~42,[3,7]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';